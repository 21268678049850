<template>
  <div class="map">
    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=GERNE%20Technology%20SARL&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
  </div>
</template>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 500px;
  overflow: hidden;
}

@media (max-width: 450px) {
  .map {
    height: 256px;
  }
  .map iframe {
    height: 256px !important;
  }
}
</style>
