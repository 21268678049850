<script setup>
import { ref } from "vue";
import LinkedButton from "@/components/additional/LinkedButton.vue";
import Accordion from "@/components/additional/Accordion.vue";

const items = ref([
  {
    title: "Campaign Design",
    text: "Easily create targeted communication campaigns tailored to diverse audiences. Our intuitive interface simplifies the process, allowing you to craft messages that resonate with different segments of your customer base.",
    image: "prod2_1.png"
  },
  {
    title: "A/B Testing",
    text: "Optimize your campaigns with built-in A/B testing functionalities. Compare different versions of your campaigns to identify what works best, ensuring you achieve the best possible results.",
    image: "prod2_2.png"
  },
  {
    title: "Customer Journey Mapping",
    text: "Gain insights into your customer’s interactions with your brand. Our tools help you visualize and understand the entire customer journey, from initial contact through conversion and beyond, enabling you to create more personalized and effective campaigns.",
    image: "prod2_3.png"
  }
])
</script>

<template>
  <section class="crm">
    <div class="container">
      <div class="crm__content">
        <div class="text flex flex-col">
<!--          <LinkedButton href="#" label="CRM SYSTEM" />-->
          <p class="text7 colored">
            <span>Empower Your Communication</span>
            Campaigns with Advanced CRM Tools
          </p>
          <p class="small3">Our CRM Module is a comprehensive tool designed to elevate your communication campaigns. Whether you're targeting a specific audience or aiming for a global reach, this module equips you with the capabilities to design, test, and optimize your marketing efforts. With features like A/B testing, customer journey mapping, and real-time feedback collection, you can ensure your campaigns are impactful and effective.</p>
        </div>
        <Accordion position="left" :items="items" crm/>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.crm {
  padding: 80px 0 110px;
  &__content {
    .text {
      width: 100%;
      max-width: 578px;
      margin-left: auto;
      margin-bottom: 80px;
      .small3 {
        width: 90%;
      }
      .text7 {
        margin: 14px 0 32px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .crm__content .text {
    width: 50%;
  }
}

@media (max-width: 900px) {
  .crm__content .text {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 700px) {
  .crm {
    padding: 40px 0 60px;
    &__content {
      .text {
        margin-bottom: 86px;
      }
    }
  }
}
</style>