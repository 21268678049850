<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
import {useNavigationStore} from "@/store/nav";
import getWindowWidth from "@/utils/getWindowWidth";

const navs = ref([
  {
    label: "home",
    block: "home",
    items: [],
    href: "/"
  },
  {
    label: "about us",
    block: "about",
    items: []
  },
  {
    label: "our story",
    block: "story",
    items: []
  },
  {
    label: "services",
    block: "services",
    items: []
  },
  {
    label: "products",
    block: "products",
    items: [
      {
        label: "magellanic",
        href: "https://magellanic.lu/"
      },
      {
        label: "CRM",
        href: "https://crm.gerne.lu/"
      },
    ]
  },
  {
    label: "partners",
    block: "partners",
    items: []
  },
  {
    label: "privacy",
    href: "/privacy",
    mobile: true
  }
])

const router = useRouter();
const activeIndex = ref(-1);
const isDropdown = ref(false);
const navStore = useNavigationStore();
const windowWidth = getWindowWidth();

const setBlock = (index) => {
  if(!!navs.value[index]?.href) {
    if(index === 0) navStore.setBlockIndex('home');
    router.push({path: navs.value[index].href})
  } else {
    if(index !== 4) {
      router.push({ path: "/" });
      activeIndex.value = index;
      navStore.setBlockIndex(navs.value[index].block);
      navStore.setBlockClicked(true);
      setTimeout(() => {
        navStore.setBlockClicked(false);
        navStore.setBlockIndex("home");
      }, 500);
    } else if (index === 4) {
      navStore.setBlockIndex('products');
      isDropdown.value = !isDropdown.value;
    }
  }
}

document.addEventListener('click', function(event) {
  const dropdownToggle = document.querySelector('.toggle');
  const dropdownMenu = document.querySelector('.nav__item--hidden');

  if (windowWidth.value > 700 && !dropdownToggle.contains(event.target) && !dropdownMenu.contains(event.target)) {
    isDropdown.value = false;
  }
});
</script>

<template>
  <nav class="nav flex justify-between items-center">
    <template v-for="(nav, index) in navs" :key="nav.label">
      <div
        v-if="!nav?.mobile"
        class="nav__item flex items-center relative"
        @click="setBlock(index)"
        :class="{'active': (index === 4 && isDropdown) || (router.currentRoute.value.name === 'home' && navStore.blockIndex === nav.block), 'toggle': index === 4}">
        <p class="small1">{{ nav.label }}</p>
        <svg v-if="nav?.items?.length !== 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M9.99999 13.2061C10.2197 13.1998 10.4206 13.1182 10.5838 12.9424L15.355 8.05818C15.4932 7.92006 15.5685 7.74428 15.5685 7.53711C15.5685 7.12277 15.242 6.79004 14.8277 6.79004C14.6268 6.79004 14.4322 6.87165 14.2878 7.01604L10.0063 11.4169L5.71218 7.01604C5.56779 6.87793 5.37945 6.79004 5.17228 6.79004C4.75794 6.79004 4.43149 7.12277 4.43149 7.53711C4.43149 7.74428 4.50682 7.92006 4.64494 8.05818L9.42242 12.9424C9.59192 13.1182 9.78026 13.2061 9.99999 13.2061Z" fill="white" fill-opacity="0.42"/>
        </svg>
        <div class="nav__item--hidden" :class="{'show': isDropdown}" v-if="nav?.items?.length !== 0">
          <a v-for="(item, id) in nav?.items" :key="id" class="small1" :href="item.href" target="_blank">{{ item.label }}</a>
        </div>
        <div :class="{'active-line' : (index === 4 && isDropdown) || (router.currentRoute.value.name === 'home' && navStore.blockIndex === nav?.block)}" class="nav__item--active absolute"></div>
      </div>
    </template>
  </nav>
</template>

<style scoped lang="scss">
.nav {
  &__item {
    color: $white;
    padding: 0 20px;
    cursor: pointer;
    & > p {
      opacity: 0.42;
      transition: all .3s ease;
    }
    & > a {
      text-decoration: none;
      color: $white;
      opacity: 0.42;
    }
    &--hidden {
      display: none;
      &.show {
        display: flex;
        flex-direction: column;
        position: absolute;
        border-radius: 10px;
        overflow: hidden;
        background: #FFF;
        width: 160px;
        z-index: 999;
        opacity: 1 !important;
        bottom: -110px;
        right: 20px;
        a {
          padding: 10px 14px;
          transition: all .3s ease;
          color: $blue-500;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: $primary;
            background: #EAEFF7;
          }
          &:active {
            color: $blue-600;
          }
        }
      }
    }
    &:last-child > a {
      display: none;
    }
    & > svg {
      margin-left: 4px;
    }
    &--active {
      width: 0;
      height: 1px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 10%, rgba(255, 255, 255, 1) 30.8%, rgba(255, 255, 255, 1) 50.8%, rgba(255, 255, 255, 1) 70.8%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 0) 100%);
      bottom: -26px;
      left: 0;
      transition: all .2s ease-in;
      &.active-line {
        width: 100%;
      }
    }
    &.active, &:hover {
      & > .small1 {
        background: linear-gradient(180deg, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 1;
      }
      svg {
        opacity: 1;
      }
      svg path {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 900px) {
  .nav {
    &__item {
      padding: 0 10px;
    }
  }
}

@media (max-width: 700px) {
  .nav {
    height: 100%;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center !important;
    gap: 32px;
    overflow: auto;
    margin: 20px 0;
    &__item {
      opacity: 1;
      padding: 0;
      flex-wrap: wrap;
      max-width: 300px;
      &--hidden.show {
        position: relative;
        bottom: 0;
        right: auto;
        left: 0;
        background: none;
        width: 100%;
        a {
          font-size: 24px;
          line-height: 44px;
          padding: 0;
          &:first-child {
            padding: 34px 0 14px;
          }
        }
      }
      svg {
        width: 35px;
        height: 29px;
      }
      & > a {
        display: block;
        text-decoration: none;
        &, &:visited {
          color: $white;
        }
      }
      .small1 {
        font-size: 34px;
        font-weight: 400;
        line-height: 44px;
        opacity: 0.6;
        transition: all .3s ease;
      }
      &--active {
        display: none;
      }
      &.active {
        .small1 {
          opacity: 1;
        }
      }
    }
  }
}
</style>