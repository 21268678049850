<script setup>
import { ref } from "vue";
import Button from "@/components/ui/Button.vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<template>
  <section class="ads">
    <div class="container">
      <div class="ads__content flex flex-col">
        <p class="text7">Embrace a simpler, more secure way to manage payments and watch your business thrive with our cutting-edge financial technology platform</p>
        <Button outline gradient @click="isModal = true">let's start</Button>
      </div>
    </div>
  </section>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<style scoped lang="scss">
.ads {
  background: linear-gradient(180deg, #1059D1 -79.91%, #0B3583 100%);
  backdrop-filter: blur(6.5px);
  &__content {
    gap: 40px;
    padding: 80px 17px;
    p {
      width: 100%;
      max-width: 916px;
      background: linear-gradient(180deg, #FFF -8.71%, rgba(255, 255, 255, 0.00) 203.79%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 700px) {
  .ads {
    &__content {
      padding: 60px 20px;
      button {
        max-width: none;
      }
    }
  }
}
</style>