<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import {useNavigationStore} from "@/store/nav";
const navs = ref([
  {
    label: "Quick links",
    items: [
      {
        label: "About us",
        block: "about"
      },
      {
        label: "Services",
        block: "services"
      },
      {
        label: "Our story",
        block: "story"
      },
      {
        label: "Partners",
        block: "partners"
      },
      {
        label: "Privacy",
        href: "/privacy"
      },
    ]
  },
  {
    label: "Services",
    items: [
      {
        label: "Software Architecture & Design",
        block: "services"
      },
      {
        label: "Enterprise Software Development",
        block: "services"
      },{
        label: "Cloud-Native Development",
        block: "services"
      },
      {
        label: "IT Security Services",
        block: "services"
      }
    ]
  },
  {
    label: "Products",
    items: [
      {
        label: "Magellanic",
        link: "https://magellanic.lu/"
      },
      {
        label: "CRM system",
        link: "https://crm.gerne.lu/"
      }
    ]
  }
])

const router = useRouter();
const navStore = useNavigationStore();

const scrollToBlock = (index, i) => {
  router.push('/');
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(navs.value[index].items[i].block);
}
</script>

<template>
  <div class="nav-block flex">
    <div
      v-for="(nav, index) in navs"
      :key="index"
      class="column flex flex-col"
    >
      <p class="small6">{{ nav.label }}</p>
      <div class="flex flex-col column__items">
        <template
          v-for="(item, i) in nav.items"
          :key="i"
        >
          <a
            v-if="!!item?.link"
            :href="item.link"
            class="small11"
          >{{ item.label }}</a>
          <p
            v-if="!!item?.block"
            class="small11"
            @click="scrollToBlock(index, i)"
          >{{ item.label }}</p>
          <RouterLink
            v-if="!!item?.href"
            :to="item.href"
            class="small11"
          >{{ item.label }}</RouterLink>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nav-block {
  gap: 64px;
  .column {
    color: #fff;
    gap: 24px;
    max-width: 222px;
    &__items {
      gap: 4px;
    }
    a, a:visited {
      color: #fff;
      text-decoration: none;
    }
  }
  .small6 {
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }
  .small11 {
    opacity: 0.7;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 0.7;
    }
  }
}

@media (max-width: 900px){
  .nav-block {
    .small6 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.56px;
    }
    .column {
      &__items {
        gap: 12px;
      }
    }
  }
}

@media (max-width: 700px) {
  .nav-block {
    gap: 40px;
    flex-direction: column;
  }
}
</style>