<script setup>
import { ref } from "vue";
import IconBlock from "@/components/additional/IconBlock.vue";
import Button from "@/components/ui/Button.vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<template>
  <section class="main-banner relative" ref="home">
    <div class="container">
      <div class="row flex flex-wrap justify-center items-center">
        <p class="headline1">Welcome</p>
        <IconBlock img="rocket2.png"/>
      </div>
      <div class="row flex flex-wrap justify-center items-center">
        <IconBlock img="star.png" small/>
        <p class="headline1">to gerne</p>
      </div>
      <div class="row flex flex-wrap justify-center items-center">
        <p class="headline1">Technology</p>
        <p class="text1">thrive into the digital era with us. We bring technical solutions to your business that satisfy the needs of today and unlock the opportunities of tomorrow</p>
      </div>
      <Button outline gradient @click="isModal = true">get in touch</Button>
    </div>
  </section>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<style scoped lang="scss">
.main-banner {
  z-index: 2;
  padding-top: 20vh;
  height: calc(100vh - 82px);
  .row {
    gap: 32px;
    &:nth-child(1) {
      .icon-block {
        margin: 0 5vw 0 10px;
      }
    }
    &:nth-child(2) {
      margin: 10px 0 16px;
    }
  }
  .headline1 {
    background: linear-gradient(180deg, #FFF 30.35%, rgba(255, 255, 255, 0.00) 92.11%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
  .text1 {
    color: $white;
    width: 100%;
    max-width: 390px;
  }
  button {
    margin: 42px auto;
  }
}

@media (max-width: 1200px) {
  .main-banner {
    padding-top: 80px;
    height: 100%;
    .row {
      gap: 20px;
      &:nth-child(1)  .icon-block {
        margin: 0;
      }
    }
  }
}

@media (max-width: 900px) {
  .main-banner {
    .row {
      &:nth-child(1) {
        .icon-block {
          margin-left: auto;
        }
      }
      &:nth-child(3) {
        text-align: center;
        align-items: center;
        gap: 32px;
        & > .text1 {
          font-size: 14px;
          opacity: 0.9;
        }
      }
    }
    button {
      margin: 32px auto 0;
    }
  }
}

@media (max-width: 700px) {
  .main-banner {
    padding-top: 24px;
    height: calc(100vh - 80px);
    .row {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      gap: 8px;
      &:nth-child(2) {
        flex-direction: column-reverse;
        margin: 10px auto 16px;
        & > p {
          margin-left: auto;
        }
        .icon-block {
          margin-right: auto;
        }
      }
    }
  }
}
</style>