<script setup>
const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<template>
  <div @click="goToTop" class="gotop flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle opacity="0.2" cx="30" cy="30" r="29.5" stroke="white"/>
      <path d="M30 37L30 22M30 22L26 26M30 22L34 26" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.gotop {
  cursor: pointer;
  svg {
    transition: all .3s ease;
  }
  &:hover {
    svg {
      fill: rgba(255, 255, 255, 0.34);
    }
  }
  &:active {
    svg {
      fill: rgba(255, 255, 255, 0.54);
    }
  }
}
</style>