<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import Card from "@/components/additional/Card.vue";
import Button from "@/components/ui/Button.vue";
import SliderControl from "@/components/additional/SliderControl.vue";
import Modal from "@/components/ui/Modal.vue";
import getWindowWidth from "@/utils/getWindowWidth";
import 'swiper/css';

const cards = ref([
  {
    title: "Cloud-Native Development",
    text: "With a wealth of cloud computing experience, Gerne Technologies offers powerful and scalable cloud solutions for businesses of all sizes. The development of cloud applications by our professional team includes all possible stages: from preliminary consultations to implementation and support. If you are planning to migrate your data to the cloud, we will be happy to help you. With our help, cloud solutions become the basis of your business infrastructure and we use the opened opportunities to create new added value for your company."
  },
  {
    title: "Software Architecture & Design",
    text: "Consulting on your Software Architecture and Design using our experience in highly available and high load environments. The right beginning for enterprises to start changing digitally is to start with Right Software Architecture. We know how to set up complex technologies infrastructure easily. You can trust us in delivering modern solutions for modernizing architectures, our products designed for evolving companies to ensure technology integration."
  },
  {
    title: "IT Security Services",
    text: "Gerne’s IT risk management practices help companies find solutions for business processes, technology implementation, and operational and financial risk management. Our goal is to help clients with risk assessment, management, and control, thereby improving the reliability of processes. We believe preventive measures provide the best value for our customers - protecting your applications before they are attacked, and our cybersecurity services reflect that."
  },
  {
    title: "Enterprise Software Development",
    text: "Architecting and developing scalable and high-performing software solutions to meet the business challenges of clients. Our engineering team always relies on the most efficient and popular technologies when developing custom solutions for enterprises. We consider any wishes of our customers and partners; therefore, the final products are always optimized to the maximum for the business with which we work."
  }
])

const windowWdt = getWindowWidth();
const isModal = ref(false);

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};

</script>

<template>
  <div class="cards flex flex-wrap relative">
    <template v-if="windowWdt > 1000">
      <Card
          v-for="(item, index) in cards"
          :key="index"
          :title="item.title"
          :text="item.text"
          :index="index"
      ></Card>
      <Button outline blue @click="isModal = true">get in touch</Button>
    </template>
    <swiper
        v-else
        ref="swiperRef"
        :slides-per-view="windowWdt > 500 ? 1.5 : 1"
        :space-between="20"
        @swiper="onSwiper"
        :modules="[Navigation]"
    >
      <swiper-slide v-for="(i, id) in cards" :key="id">
        <Card
          :title="i.title"
          :text="i.text"
          :index="id"
        ></Card>
      </swiper-slide>
      <div class="controls flex items-center">
        <SliderControl
            @click="goPrev"
            :disabled="swiperInstance?.activeIndex === 0"
        ></SliderControl>
        <SliderControl
            @click="goNext"
            btnType="next"
            :disabled="swiperInstance?.activeIndex === cards.length-1"
        ></SliderControl>
      </div>
    </swiper>

  </div>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<style scoped lang="scss">
.cards {
  gap: 32px;
  margin: 110px 0 0 64px;
  width: 100%;
  max-width: 1114px;
  .card {
    &:nth-child(2),
    &:nth-child(4) {
      transform: translateY(-222px);
    }
  }
  button {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 541px;
    width: calc(50% - 16px);
    height: 190px;
  }
}

@media (max-width: 1350px) {
  .cards {
    margin: 100px 0 0;
    .card {
      &:nth-child(2),
      &:nth-child(4) {
        transform: translateY(-170px);
      }
    }
    button {
      height: 130px;
    }
  }
}

@media (max-width: 1000px) {
  .cards {
    width: calc(100vw - 60px);
    .swiper-slide:last-child {
      padding-right: 60px;
    }
    .swiper-slide {
      height: auto;
    }
  }
  .swiper {
    height: 100%;
  }
  .controls {
    margin-top: 32px;
    gap: 14px;
  }
}
@media (max-width: 900px) {
  .cards {
    width: calc(100vw - 40px);
    margin: 50px 0 0;
    .swiper-slide:last-child {
      padding-right: 40px;
    }
  }
}
@media (max-width: 700px) {
  .cards {
    width: 100%;
    .swiper-slide:last-child {
      padding-right: 0px;
    }
  }
}
</style>