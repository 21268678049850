<template>
  <div class="spiral-container">
    <div id="spiral" class="spiral"></div>
    <div id="spiral2" class="spiral"></div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

const words = "gerne technology";
const ANIMATION_DURATION = 6000;
let ANGLE = 360;

const createElement = (char, i, delay) => {
  const div = document.createElement("div");
  div.innerText = char;
  div.classList.add("character");
  div.style.animationDelay = `${delay - i * (ANIMATION_DURATION / 30)}ms`;
  return div;
};

const animateElements = (elements, angleOffset) => {
  elements.forEach((el, i) => {
    const translateY = Math.sin((ANGLE + angleOffset) * (Math.PI / 180)) * (-100);
    const scale = Math.cos((ANGLE + angleOffset) * (Math.PI / 180)) * 0.5 + 0.5;
    const delay = i * (ANIMATION_DURATION / 30) - 120;

    setTimeout(() => {
      el.style.transform = `translateY(${translateY}px) scale(${scale})`;
    }, delay);
  });
};

const animation = () => {
  ANGLE -= 1;
  const spiralElements = document.querySelectorAll("#spiral .character");
  const spiral2Elements = document.querySelectorAll("#spiral2 .character");

  animateElements(spiralElements, 0);
  animateElements(spiral2Elements, 180);

  requestAnimationFrame(animation);
};

onMounted(() => {
  const spiralDelay = 0;
  const spiral2Delay = ANIMATION_DURATION / 2;

  words.split("").forEach((char, i) => {
    document.querySelector("#spiral").append(createElement(char, i, spiralDelay));
    document.querySelector("#spiral2").append(createElement(char, i, spiral2Delay));
  });

  animation();
});
</script>

<style lang="scss">
.spiral-container {
  margin: 150px 0 60px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  opacity: 0;
  animation: showen 4000ms ease forwards;
  animation-delay: 1000ms;
}

@keyframes showen {
  to {
    opacity: 1;
  }
}

.spiral {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
}

.character {
  font-size: 55px;
  color: $primary;
  text-transform: uppercase;
  transition: transform 0.1s linear;
}

@media (max-width: 900px) {
  .character {
    font-size: 40px;
  }
}

@media (max-width: 550px) {
  .spiral-container {
    height: 200px;
  }
  .character {
    font-size: 16px;
  }
}
</style>
