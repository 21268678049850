<script setup>
import { ref, watch } from "vue";
import Logo from "@/components/common/Logo.vue";
import Nav from "@/components/common/Nav.vue";
import MenuBtn from "@/components/mobile/MenuBtn.vue";
import Menu from "@/components/mobile/Menu.vue";
import {useNavigationStore} from "@/store/nav";

const isMenu = ref(false);

const navStore = useNavigationStore();

watch(() => isMenu.value, () => {
  if(isMenu.value) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})

watch(() => navStore.blockIndex, () => {
  if(navStore.blockIndex !== "products") isMenu.value = false;
})

</script>

<template>
  <header class="header relative">
    <div class="container">
      <div class="header__content flex items-end justify-between">
        <RouterLink to="/"><Logo /></RouterLink>
        <Nav />
        <MenuBtn @click="isMenu = true" />
      </div>
    </div>
  </header>
  <Menu :isOpen="isMenu" @close="isMenu = false"></Menu>
</template>

<style scoped lang="scss">
.header {
  z-index: 3;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
  &__content {
    padding: 25px 0;
    a {
      max-height: 31px;
    }
    .menu-btn {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .header {
    &__content {
      a svg {
        width: 80px;
      }
    }
  }
}

@media (max-width: 700px) {
  .header {
    border-bottom: none;
    &__content {
      nav {
        display: none;
      }
      .menu-btn {
        display: flex;
      }
    }
  }
}
</style>