<script setup>
import { ref } from "vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<template>
  <div class="getintouch relative flex items-center justify-between">
    <div class="content flex flex-col">
      <p class="text6">Headquarters in Contern, Luxembourg</p>
      <p class="small11">Our Headquarters is located in Contern and are 11 minutes drive time (about 8km) from Luxembourg International Airport and 15 minutes drive time (about 10km) from Luxembourg City Center.</p>
    </div>
    <div class="action" @click="isModal = true">
      <div class="circle"></div>
      <p class="text345">get in touch</p>
    </div>
  </div>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<style scoped lang="scss">
.getintouch {
  padding: 32px 48px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.08);
  overflow: hidden;
  gap: 30px;
  .content {
    width: 100%;
    max-width: 515px;
    gap: 20px;
    color: $white-100;
    .small11 {
      width: 95%;
    }
  }
  .circle {
    position: absolute;
    right: -85px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 519px;
    background: rgba(255, 255, 255, 0.18);
    font-size: 519px;
    width: 1em;
    height: 1em;
    transition: all .3s ease;
    @include breakpoint(large) {
      font-size: 450px;
    }
  }
  .action {
    margin-right: 80px;
    cursor: pointer;
    @include breakpoint(large) {
      margin-right: 0px;
    }
    p {
      color: $white;
      white-space: nowrap;
    }
    &:hover {
      .circle {
        background: rgba(255, 255, 255, 0.38);
      }
    }
    &:active {
      .circle {
      background: rgba(255, 255, 255, 0.48);
      }
    }
  }
}

@media (max-width: 900px) {
  .getintouch {
    padding: 20px;
    gap: 16px;
    .circle {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .getintouch {
    flex-direction: column;
    background: none;
    border-radius: 0;
    padding: 0;
    gap: 40px;
    .content {
      max-width: 100%;
      gap: 14px;
      .small11 {
        opacity: 0.8;
        font-size: 16px;
        line-height: 30px;
      }
    }
    .action {
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.08);
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>