<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import IconBlock from "@/components/additional/IconBlock.vue";
import TextBlock from "@/components/additional/TextBlock.vue";
import InfoBlock from "@/components/additional/InfoBlock.vue";

const items = ref([
  {
    image: "story2.png",
    text: "The company provides full-cycle services for the development and maintenance of software solutions to clients globally. It offers a set of software development and technology expertise, from requirements study and IT consulting to long-term support. For clients, this means hiring only one contractor to carry out all the IT work. The company will take responsibility for creating a high-quality solution, ensuring transparency of operations and full correspondence to the time and budget terms of the contract."
  },
  {
    image: "story1.png",
    text: "For clients, this means hiring only one contractor to carry out all the IT work. The company will take responsibility for creating a high-quality solution, ensuring transparency of operations and full correspondence to the time and budget terms of the contract."
  }
])
</script>

<template>
  <section class="story" ref="story">
    <div class="container">
      <div class="story__content">
        <SectionTitle white>our story</SectionTitle>
        <div class="s-banner flex flex-col justify-center items-center">
          <p class="headline2">Our company</p>
          <div class="flex">
            <IconBlock img="spiral.png" />
            <p class="headline2">was founded</p>
          </div>
          <div class="flex">
            <p class="headline2">in 2014</p>
            <IconBlock img="sphere.png" />
          </div>
        </div>
        <TextBlock :items="items" />
        <InfoBlock />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.story {
  background: linear-gradient(180deg, #1059D1 -79.91%, #0B3583 100%);
  backdrop-filter: blur(6.5px);
  padding: 80px 0 138px;
  .s-banner {
    margin: 80px auto;
    width: 100%;
    max-width: 1010px;
    gap: 14px;
    & > .flex:nth-child(2) {
      p {
        white-space: nowrap;
      }
      .icon-block img {
        transform: translateY(-13px);
      }
    }
    & > .flex:nth-child(3) {
      .icon-block img {
        transform: translateY(-29px);
      }
    }
    p {
      background: linear-gradient(180deg, #FFF 17.39%, rgba(255, 255, 255, 0.00) 91.3%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
    }
    & > .flex {
      gap: 31px;
    }
    & > p {
      width: 85%;
    }
  }
}

@media (max-width: 700px) {
  .story {
    padding: 40px 0 60px;
    .s-banner {
      margin: 40px 0;
      gap: 10px;
      > p {
        width: 100%;
        text-align: center;
      }
      & > .flex {
        gap: 10px;
        flex-direction: column;
        align-items: center;
        &:nth-child(2) {
          .icon-block img {
            width: 70%;
            transform: translateY(-9px);
          }
        }
        &:nth-child(3) {
          flex-direction: column-reverse;
          .icon-block img {
            width: 100%;
            transform: translateY(-16px);
          }
        }
      }

    }
  }
}

@media (max-width: 380px) {
  .story .s-banner {
    .headline2 {
      font-size: 40px;
    }

  }
}
</style>