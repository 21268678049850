<script setup>
import { ref } from "vue";

const items = ref([
  {
    image: "partner1.svg",
    label: "Amazon Web Services"
  },
  {
    image: "partner2.svg",
    label: "Microsoft Azure"
  },
  {
    image: "partner3.svg",
    label: "Google Cloud Platform"
  },
  {
    image: "partner4.svg",
    label: "Kubernetes"
  }
])
</script>

<template>
  <div class="partner-block flex">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="block flex flex-col"
    >
      <p class="small5">0{{ index+1 }}</p>
      <img :src="require(`@/assets/img/${item.image}`)" alt="gerne partner" />
      <p class="text34">{{ item.label }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.partner-block {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #CFCFCF;
  .block {
    padding: 32px 32px 41px 32px;
    width: 25%;
    .small5 {
      line-height: 12px;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    .text34 {
      margin-top: -5px;
    }
    img {
      width: 187px;
      margin: 23px auto 0;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-right: 1px solid #CFCFCF;
    }
  }
}

@media (max-width: 1200px) {
  .partner-block {
    .block {
      padding: 20px 20px 30px;
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .partner-block {
    flex-wrap: wrap;
    .block {
      width: 50%;
      max-height: 300px;
      img {
        max-width: 180px;
      }
      &:nth-child(2) {
        border-right: none;
      }
      &:nth-child(3),
      &:nth-child(4) {
        border-top: 1px solid #CFCFCF;
      }
      &:nth-child(2),
      &:nth-child(4) {
        .small5 {
          text-align: right;
        }
      }
    }
  }
}
</style>