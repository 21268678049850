<template>
  <div class="home">
    <div class="home__top relative">
      <video class="absolute" id="vid" playsinline="" defaultmuted="" autoplay="" muted="" loop="">
        <source width="100%" src="@/assets/video/background.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>

      <Header />
      <Welcome ref="home"/>
    </div>
    <AboutUs ref="about"/>
    <Services ref="services"/>
    <Products />
    <Ads />
    <CRM />
    <Story ref="story"/>
    <Partners ref="partners"/>
    <Map />
    <Footer/>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from "vue";
import Header from "@/components/common/Header.vue";
import Welcome from "@/components/sections/Welcome.vue";
import AboutUs from "@/components/sections/AboutUs.vue";
import Services from "@/components/sections/Services.vue";
import Products from "@/components/sections/Products.vue";
import Ads from "@/components/sections/Ads.vue";
import CRM from "@/components/sections/CRM.vue";
import Story from "@/components/sections/Story.vue";
import Partners from "@/components/sections/Partners.vue";
import Map from "@/components/sections/Map.vue";
import Footer from "@/components/common/Footer.vue";
import {useNavigationStore} from "@/store/nav";

const navigationStore = useNavigationStore();
const home = ref(null);
const about = ref(null);
const services = ref(null);
const partners = ref(null);
const story = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(newValue);
    });
  }
}, {deep: true})

const getBlockRef = (index) => {
  switch(index) {
    case 'home':
      return home.value;
    case 'about':
      return about.value;
    case 'story':
      return story.value;
    case 'services':
      return services.value;
    case 'partners':
      return partners.value;
  }
}

const scrollToBlock = (blockIndex) => {
  if(blockIndex !== 'products') {
    const blockRef = getBlockRef(blockIndex);
    if (blockRef && blockRef.$el) {
      blockRef.$el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

onMounted(() => {
  if(navigationStore.blockIndex === 0) navigationStore.setBlockIndex('home');
  if (navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
})

onBeforeUnmount(() => {
  navigationStore.setBlockIndex(0);
  navigationStore.setBlockClicked(false);
})
</script>

<style lang="scss">
.home {
  &__top {
    overflow: hidden;
    @include breakpoint(medium) {
      padding-bottom: 74px;
    }
    video {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1200px) {
  .home__top {
    video {
      height: 100%;
    }
  }
}

@media (max-width: 700px) {
  .home__top {
    video {
      height: 108vh;
    }
  }
}
</style>