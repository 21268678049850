<template>
  <div class="response-modal flex items-center justify-center">
    <video class="absolute" id="vid" playsinline="" defaultmuted="" autoplay="" muted="">
      <source width="100%" src="@/assets/video/background.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="response-modal__content relative">
      <svg @click="$emit('close', 'hide')" class="absolute" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="block flex flex-col items-center">
        <p class="text89 white">{{ isSuccess ? 'Success' : 'Error' }}</p>
        <div class="flex flex-col items-center">
          <img :src="require(`@/assets/img/${isSuccess ? 'success.png' : 'error.png'}`)" alt="gerne response modal icon" />
          <p v-if="isSuccess" class="white text2">Your application has been accepted. <br/>You will be contacted shortly for further details.</p>
          <p v-else class="white text2">Something went wrong. <br/>Please try again!</p>
        </div>
        <div class="flex buttons">
          <template v-if="isSuccess">
            <Button class="green" outline gradient @click="getContinueOrHide('hide')">continue</Button>
          </template>
          <template v-else>
            <Button outline gradient @click="getContinueOrHide('hide')">close</Button>
            <Button outline gradient @click="getContinueOrHide">try again</Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['close']);

const props = defineProps({
  isSuccess: Boolean
})

const getContinueOrHide = (val) => {
  if(val === 'hide') {
    emit('close', 'hide');
  } else {
    emit('close', 'cont');
  }
}
</script>

<style lang="scss" scoped>
.response-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__content {
    color: $white;
    padding: 40px;
    max-width: 500px;
    width: 100%;
    border-radius: 33px;
    border: 1px solid var(--white-10, rgba(217, 217, 217, 0.10));
    background: rgba(255, 255, 255, 0.03);
    button {
      max-width: none !important;
    }
    svg {
      right: 24px;
      top: 24px;
    }
    img {
      width: 200px;
      height: 200px;
    }

    .block {
      gap: 18px;

      & > .flex-col {
        gap: 8px;
        text-align: center;
        max-width: 420px;
        width: 100%;
      }

      .buttons, button {
        gap: 8px;
        width: 100%;

      }
    }
  }
}

@media (max-width: 700px) {
  .response-modal {
    &__content {
      padding: 32px 22px;
      width: calc(100% - 40px);
      .block {
        .buttons {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
</style>