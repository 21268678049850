<script setup>
import { ref, nextTick } from "vue";
import Button from "@/components/ui/Button.vue";

const props = defineProps({
  position: {
    type: String,
    default: 'right',
  },
  items: Array,
  crm: Boolean
});

const activeIndex = ref(0);

const setActiveIndex = (index, event) => {
  event.preventDefault();
  const targetElement = document.getElementById(`item-${index}${props.crm}`);
  activeIndex.value = index;
  if(window.innerWidth <= 700) {
    nextTick(() => {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }
}
</script>

<template>
  <div
      class="accordion flex justify-between"
      :class="{'left': position === 'left'}"
  >
    <div class="wrap">
      <div class="text flex flex-col">
        <div
            class="item flex flex-col relative"
            v-for="(item, i) in items"
            :key="i"
            :id="`item-${i}${crm}`"
            :class="{'active relative': activeIndex === i}"
        >
          <div class="line absolute" :class="{'active': activeIndex === i}"></div>
          <div class="item__top flex justify-between items-center" @click="setActiveIndex(i, $event)">
            <p class="text5">{{ item.title }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M15.0011 19.8113C15.3307 19.8019 15.632 19.6794 15.8769 19.4158L23.0337 12.0895C23.2409 11.8823 23.3539 11.6186 23.3539 11.3079C23.3539 10.6863 22.8642 10.1873 22.2427 10.1873C21.9413 10.1873 21.6494 10.3097 21.4328 10.5263L15.0105 17.1275L8.56941 10.5263C8.35282 10.3191 8.07031 10.1873 7.75956 10.1873C7.13805 10.1873 6.64837 10.6863 6.64837 11.3079C6.64837 11.6186 6.76137 11.8823 6.96854 12.0895L14.1348 19.4158C14.389 19.6794 14.6715 19.8113 15.0011 19.8113Z" fill="#7B97B7"/>
            </svg>
          </div>
          <div class="item__hidden" :class="{'show': activeIndex === i}">
            <p v-show="activeIndex === i" class="small3">{{ item.text }}</p>
            <img
                v-show="activeIndex === i"
                class="mob-img"
                :src="require(`@/assets/img/products/${item.image}`)"
                alt="gerne product image"
            />
          </div>
        </div>
      </div>
      <a v-if="crm" href="https://crm.gerne.lu/" target="_blank"><Button outline blue>crm system</Button></a>
    </div>
    <div class="image">
      <template v-for="(item, i) in items" :key="i">
        <img
            v-if="activeIndex === i"
            v-motion
            :initial="{ opacity: 0.5, y: 40 }"
            :visibleOnce="{ opacity: 1, y: 0, scale: 1 }"
            :duration="500"
            :src="require(`@/assets/img/products/${item.image}`)"
            alt="gerne product image"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion {
  height: auto;
  &.left {
    flex-direction: row-reverse;
    .image {
      transform: translateY(-70px);
    }
  }
  .wrap {
    width: 50%;
    max-width: 578px;
    height: min-content;
    a {
      display: block;
      text-decoration: none;
      max-width: 491px;
      margin-top: 80px;
    }
  }
  .text {
    height: 100%;
    border-left: 1px solid $grey-100;
  }
  .image {
    height: 100%;
    max-height: 580px;
    img {
      height: 100%;
      object-fit: cover;
      width: auto;
    }
  }
  .item {
    transition: all .3s ease;
    .mob-img {
      display: none;
    }
    .line {
      width: 2px;
      background: $primary;
      left: 0;
      top: 0;
      height: 0;
      &.active {
        height: 100%;
      }
    }
    svg {
      transition: all .3s ease;
    }
    &.active {
      svg {
        transform: rotate(180deg);
        path {
          fill: $primary;
        }
      }
      .item__top .text5 {
        color: $primary;
      }
    }
    &__top,
    &__hidden {
      width: 100%;
      max-width: 521px;
    }
    &__top {
      cursor: pointer;
      padding: 33px;
      .text5 {
        color: $blue-300;
      }
    }
    &__hidden {
      overflow: hidden;
      height: 0;
      transition: height 0.5s ease;
      &.show {
        height: 100%;
        padding: 28px 33px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .accordion {
    .image {
      width: 50%;
      max-width: 600px;
      transform: translateX(10%);
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .accordion {
    &.left {
      flex-direction: row;
    }
    .wrap {
      width: 100%;
      max-width: 600px;
      button {
        margin-top: 40px;
      }
    }
    .image {
      display: none;
    }
    .text {
      width: 100%;
      max-width: none;
    }
    .item {
      &__top {
        max-width: none;
        padding: 35px 12px 30px 24px;
        &:focus,
        &:active {
          outline: none;
          background: none;
        }
        svg {
          min-width: 30px;
        }
      }
      &__hidden {
        max-width: none;
        &.show {
          padding: 28px 24px 10px;
          overflow: hidden;
        }
      }
      .mob-img {
        transform: translateX(-12%);
        width: 140%;
        display: block;
        max-width: 400px;
      }
    }
  }
}
</style>
