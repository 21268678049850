<template>
  <form class="flex flex-col relative" id="formm" @submit.prevent="send">
    <div class="inputs flex items-center">
      <Input placeholder="Your name" v-model="data.name" :isError="isError && data.name.toString().trim().length == 0" />
      <Input placeholder="Your email" v-model="data.email" :isError="isError && !emailRegex.test(data.email)" type="email" />
    </div>
    <Input placeholder="Subject" v-model="data.subject" />
    <Input textarea placeholder="Your message" v-model="data.message"/>
    <div class="f-action flex items-center justify-between">
      <label class="custom-checkbox flex items-center" :class="{'checked': isChecked}" for="ch-1" @click="isChecked = !isChecked" :key="isChecked" required>
        <input required type="checkbox" id="ch-1" :checked="isChecked"/>
        <span class="checkmark"></span>
        <span class="check-text">I have read and accept the <RouterLink class="no-underline" to="/privacy">Privacy Policy</RouterLink>.</span>
      </label>
      <Button type="submit" outline gradient>send a message</Button>
    </div>
  </form>
</template>

<script setup>
import { ref, watch, onUnmounted } from "vue";
import Input from "./Input.vue";
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['response']);

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: '',
});

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

watch(isSent, () => {
  if(!isSent.value) resetForm()
})

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key == 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
      .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        emit('response', true);
        // resetForm();
      })
      .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        emit('response', false);
        // resetForm();
      })
}
const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Gerne Technologies Website');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "moien@gerne.lu");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Gerne Technologies Website Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
})
</script>

<style lang="scss">
form {
  gap: 20px;
  margin-top: 60px;
  z-index: 3;
  width: 100%;
  button {
    max-width: 232px !important;
    height: 70px !important;
    p {
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 28px !important;
    }
  }
}
.inputs {
  gap: 20px;
  .input {
    width: calc(50% - 10px);
  }
}
.custom-checkbox {
  position: relative;
  height: min-content;
  cursor: pointer;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  .check-text {
    opacity: 0.5;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    transition: all .2s ease;
    a, a:visited, a:focus, a:active {
      color: $white;
      transition: color .2s ease;
    }
  }
  .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(13px);
    transition: border .2s ease;
  }

  &.checked {
    .checkmark {
      border-radius: 6px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("@/assets/icons/check.svg");
      transition: opacity .2s ease;
      border: 1px solid rgba(255, 255, 255, 0.5) !important;
      &::before {
        display: block;
      }
    }
    &:hover {
      .checkmark {
        border: 1px solid rgba(255, 255, 255) !important;
      }
      .check-text, .check-text a {
        opacity: 1;
        color: $white !important;
      }
    }
  }
  & span:nth-child(3) {
    padding-left: 8px;
  }
  &:not(&.checked){
    &:hover {
      .checkmark {
        border: 1px solid rgba(255, 255, 255, 0.4) !important;
      }
      .check-text, .check-text a {
        opacity: 1;
        color: rgba(217, 217, 217, 0.90) !important;
      }
    }
  }
}

@media (max-width: 700px) {
  form {
    gap: 20px;
    button {
      width: 100%;
      max-width: none !important;
    }
  }
  .inputs {
    flex-direction: column;
    .input {
      width: 100%;
    }
  }
  .f-action {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start !important;
    button {
      width: 100%;
      height: 54px;
    }
  }
}
</style>
