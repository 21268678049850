import { ref, onMounted, onBeforeUnmount } from 'vue';

export default function getWindowWidth() {
    const width = ref(window.innerWidth);

    const updateWidth = () => {
        width.value = window.innerWidth;
    };

    onMounted(() => {
        window.addEventListener('resize', updateWidth);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', updateWidth);
    });

    return width;
}
