<template>
  <a class="linkedin absolute" href="https://lu.linkedin.com/company/gerne" target="_blank">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <g clip-path="url(#clip0_95_350)">
        <path opacity="0.2" d="M43.5 21.9995C43.5 33.8736 33.8741 43.4995 22 43.4995C10.1259 43.4995 0.5 33.8736 0.5 21.9995C0.5 10.1254 10.1259 0.499512 22 0.499512C33.8741 0.499512 43.5 10.1254 43.5 21.9995Z" stroke="white"/>
        <path d="M30 22.5827V28H26.9987V22.9458C26.9987 21.6767 26.5654 20.81 25.4787 20.81C24.6494 20.81 24.1567 21.3935 23.9392 21.9585C23.8602 22.1604 23.8398 22.4408 23.8398 22.7239V27.9998H20.8383C20.8383 27.9998 20.8786 19.4396 20.8383 18.5535H23.84V19.8921C23.834 19.9026 23.8255 19.9129 23.8201 19.923H23.84V19.8921C24.2389 19.2498 24.9502 18.3316 26.545 18.3316C28.5196 18.3316 30 19.6817 30 22.5827ZM17.6984 14C16.6717 14 16 14.7053 16 15.6319C16 16.5388 16.6522 17.2645 17.659 17.2645H17.6785C18.7253 17.2645 19.3762 16.5388 19.3762 15.6319C19.3563 14.7053 18.7253 14 17.6984 14ZM16.1784 28H19.1788V18.5535H16.1784V28Z" fill="#F1F2F2"/>
      </g>
      <defs>
        <clipPath id="clip0_95_350">
          <rect width="44" height="44" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </a>
</template>

<style scoped lang="scss">
.linkedin {
  svg {
    transition: all .3s ease;
  }
  &:hover {
    svg {
      fill: rgba(255, 255, 255, 0.34);
    }
  }
  &:active {
    svg {
      fill: rgba(255, 255, 255, 0.54);
    }
  }
}
@media (max-width: 900px) {
  .linkedin svg {
    width: 60px;
    height: 60px;
  }
}
</style>