<script setup>
import { computed, ref } from "vue";
const props = defineProps({
  title: String,
  index: Number,
  text: String,
})

const backColor = computed(() => ({
  background: props.index === 0 ?
      "radial-gradient(71.33% 71.33% at 50% 50%, #86BBEF 0%, #62A8EC 100%), #D9D9D9" :
      props.index === 1 ?
          "radial-gradient(71.33% 71.33% at 50% 50%, #84B6FD 0%, #81B3FC 100%), #D9D9D9" :
          props.index === 2 ?
              "radial-gradient(71.33% 71.33% at 50% 50%, #1B65DD 0%, #1059D1 100%), #D9D9D9" :
              "radial-gradient(71.33% 71.33% at 50% 50%, #84B6FD 0%, #81B3FC 100%), #D9D9D9"
}))

const isActive = ref(false);

</script>

<template>
  <div class="card relative" :class="{'active': isActive}" @click="isActive = !isActive">
    <div class="front">
      <img :src="require(`@/assets/img/cards/card${props.index+1}_bg.png`)" class="card__bg absolute c-desktop" alt="card background image" />
      <img :src="require(`@/assets/img/cards/card${props.index+1}_bg_mob.png`)" class="absolute c-mobile" alt="card background image" />
      <img :src="require(`@/assets/img/cards/card${index+1}_img.png`)" class="card__img absolute c-desktop" alt="card image" />
      <p class="text8 relative">{{ title }}</p>
    </div>
    <div class="back flex flex-col" :style="backColor">
      <p class="text8">{{ title }}</p>
      <p class="small4">{{ text }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  width: calc(50% - 16px);
  height: 50vw;
  max-width: 541px;
  max-height: 511px;
  background:transparent;
  transform-style: preserve-3d;
  perspective: 5000px;
  .c-mobile {
    display: none;
  }
  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    color: $white;
    backface-visibility: hidden;
    transition: transform 1s;
    border-radius: 50px;
    overflow: hidden;
  }
  .back {
    transform: rotateY(180deg);
    padding: 52px 52px 43px;
    gap: 28px;
  }
  .front {
    img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .text8 {
      z-index: 2;
      margin: 52px;
      max-width: 390px;
    }
  }
}

@media (min-width: 700px) {
  .card:hover{
    .front {
      transform: rotateY(-180deg);
    }
    .back {
      transform: rotateY(0deg);
    }
  }
}

@media (max-width: 700px) {
  .card.active {
    .front {
      transform: rotateY(-180deg);
    }
    .back {
      transform: rotateY(0deg);
    }
  }
}

@media (max-width: 1350px) {
  .card {
    max-height: none;
    height: auto;
    min-height: 55vw;
    .back {
      padding: 30px;
    }
  }
}

@media (max-width: 1000px) {
  .card {
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 570px;
    .c-desktop {
      display: none;
    }
    .c-mobile {
      display: block;
    }
    .back {
      padding: 42px 32px 56px;
      position: relative;
      gap: 20px;
      .small4 {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 500px) {
  .card{
    .front {
      border-radius: 30px;
      .text8 {
        margin: 42px 60px 0 32px;
      }
      .card__img {
        width: 500px !important;
        height: auto;
        left: -50px;
        top: 60px;
      }
    }
    .back {
      border-radius: 30px;
    }
  }
}
</style>