<script setup>
import { ref, watch, onUnmounted } from "vue";
import Logo from "@/components/common/Logo.vue";
import Nav from "@/components/common/Nav.vue";

const emit = defineEmits(['close']);
const props = defineProps({
  isOpen: Boolean
});

const isCrossClicked = ref(false);

watch(() => props.isOpen, () => {
  if(!props.isOpen) {
    isCrossClicked.value = false;
    document.body.classList.remove("no-scroll");
  } else {
    document.body.classList.add("no-scroll");
  }
})

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
})

</script>

<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="menu">
      <div class="bg absolute">
        <img src="@/assets/img/bg.jpg" alt="background image" />
      </div>
      <div class="menu__content relative flex flex-col">
        <div class="head flex justify-between items-center">
          <RouterLink to="/"><Logo/></RouterLink>
          <svg @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M0.957322 20.7324C0.358485 21.3312 0.329969 22.4004 0.97158 23.0277C1.59893 23.655 2.66829 23.6408 3.26712 23.042L11.993 14.3025L20.7332 23.042C21.3463 23.655 22.4014 23.655 23.0288 23.0277C23.6418 22.3862 23.6561 21.3454 23.0288 20.7324L14.3028 11.9929L23.0288 3.26764C23.6561 2.65459 23.6561 1.59958 23.0288 0.972276C22.3871 0.359228 21.3463 0.344971 20.7332 0.958019L11.993 9.69751L3.26712 0.958019C2.66829 0.359228 1.58468 0.330715 0.97158 0.972276C0.344227 1.59958 0.358485 2.66885 0.957322 3.26764L9.69749 11.9929L0.957322 20.7324Z" fill="white" fill-opacity="0.5"/>
          </svg>
        </div>
        <Nav />
        <p class="small6">©2024 GERNE Technology SARL. All Rights Reserved</p>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  .bg {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__content {
    padding: 0 28px 26px;
    z-index: 9999;
    height: 100%;
    & > p {
      color: $white;
      opacity: 0.4;
    }
  }
  .head{
    height: 90px;
  }
  a {
    height: 31px;
    svg {
      width: 80px;
    }
  }
  .small6 {
    margin-top: auto;
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}
</style>