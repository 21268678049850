<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import LinkedButton from "@/components/additional/LinkedButton.vue";
import Accordion from "@/components/additional/Accordion.vue";
import Button from "@/components/ui/Button.vue";

const items1 = ref([
  {
    title: "Security You Can Trust",
    text: "We take security seriously. Our platform is PCI DSS Level 1 Certified, ensuring the highest standards of data protection and payment security. You can trust that every transaction is handled with the utmost care, safeguarding your business and your customers.",
    image: "prod1.png"
  },
  {
    title: "Streamlined Integration",
    text: "Forget about the hassle of dealing with multiple contracts and integrations. With our platform, you have a single contract and integration point, giving you instant access to a comprehensive portfolio of vendors and market entry opportunities.",
    image: "prod2.png"
  },
  {
    title: "Web and Mobile Commerce Components",
    text: "Our platform is equipped with essential components for both web and mobile commerce. Whether your customers are shopping on their desktops or mobile devices, you can provide a seamless and consistent payment experience across all channels.",
    image: "prod3.png"
  },
  {
    title: "Data Protection",
    text: "Our platform includes services for encryption and tokenization, providing an extra layer of security for sensitive data. These services help protect your business from data breaches and ensure that customer information remains confidential.",
    image: "prod4.png"
  }
])
</script>

<template>
  <section class="products">
    <div class="container">
      <SectionTitle>products</SectionTitle>
      <div class="products__content">
<!--        <LinkedButton href="https://magellanic.lu/" label="Magellanic"></LinkedButton>-->
        <div class="products__text flex flex-col">
          <p class="text7 colored">
            <span>Making Payments Simple & Secure</span>
            meet the financial technology platform to help realize your ambitions fast
          </p>
          <p class="small3">Our platform is designed to provide a seamless and secure payment experience, ensuring that your business operations are efficient and worry-free.</p>
        </div>
        <Accordion :items="items1"></Accordion>
        <a href="https://magellanic.lu/" target="_blank"><Button outline blue>magellanic</Button></a>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.products {
  padding: 0 0 110px;
  &__content {
    margin: 80px 17px 0;
    a {
      display: block;
      text-decoration: none;
      margin-top: 60px;
    }
  }
  &__text {
    margin: 14px 0 80px;
    gap: 32px;
    width: 100%;
    max-width: 561px;
    .small3 {
      width: 90%;
    }
  }
}

@media (max-width: 700px) {
  .products {
    padding: 0 0 60px;
    &__content {
      margin: 32px 0 0;
      button {
        margin-top: 40px;
        width: 100%;
        max-width: none;
      }
    }
    &__text {
      margin: 12px 0 40px;
    }
  }
}
</style>