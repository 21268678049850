<template>
  <div class="info flex justify-between items-center">
    <div class="left flex flex-col">
      <p class="text4">Realizing the customer’s uniqueness, we worked out a mechanism for versatile adaptation to the features of each client's business. In doing so, the team strives to bring the best practices proven by international experience into the internal processes of their clients</p>
      <p class="small4">Over the years of successful work on complex projects, GERNE Technology team has accumulated unique experience both in the technological field and in various vertical and horizontal areas. The formation of a dedicated team for the implementation of a project - developers, software architects, business analysts, quality control specialists, coordinator and project manager - is carried out taking into account the domain experience and specific technical skills of each of the participants. Our developers are not just programmers - they are versatile specialists with a deep understanding of business requirements, modeling professionals and high-class programmers.</p>
    </div>
    <div class="right flex flex-wrap">
      <img src="@/assets/img/info1.png" alt="gerne info block picture" />
      <img src="@/assets/img/info2.png" alt="gerne info block picture" />
      <img src="@/assets/img/info3.png" alt="gerne info block picture" />
      <img src="@/assets/img/info4.png" alt="gerne info block picture" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.info {
  margin: 130px auto 0;
  width: 100%;
  max-width: 1072px;
  .left {
    width: 100%;
    max-width: 574px;
    color: $white;
    gap: 22px;
    .small4 {
      opacity: 0.7;
    }
  }
  .right {
    width: 374px;
    min-width: 374px;
    gap: 14px;
  }
}

@media (max-width: 1200px) {
  .info {
    gap: 40px;
  }
}

@media (max-width: 900px) {
  .info {
    flex-direction: column-reverse;
    margin: 40px auto 0;
    gap: 32px;
    .left {
      max-width: none;
      gap: 32px;
      .small4 {
        font-size: 16px;
      }
    }
    .right {
      min-width: 0;
      width: 100%;
      max-width: 374px;
      img {
        width: calc(50% - 8px);
        object-fit: cover;
      }
    }
  }
}
</style>